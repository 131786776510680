import busFrontImg from "../img/mobilityCut/busFront.svg";
import busBackImg from "../img/mobilityCut/busBack.svg";
import busAndCycleFrontImg from "../img/mobilityCut/busAndCycleFront.svg";
import busAndCycleBackImg from "../img/mobilityCut/busAndCycleBack.svg";
import busAndCarFrontImg from "../img/mobilityCut/busAndCarFront.svg";
import busAndCarBackImg from "../img/mobilityCut/busAndCarBack.svg";
import tramImg from "../img/mobilityCut/tram.svg";
import carFrontImg from "../img/mobilityCut/carFront.svg";
import carBackImg from "../img/mobilityCut/carBack.svg";
import carBackDoubleImg from "../img/mobilityCut/carBackDouble.svg";
import carBackParkedImg from "../img/mobilityCut/carBackParked.svg";
import carFrontParkedImg from "../img/mobilityCut/carFrontParked.svg";
import femaleWalkingImg from "../img/mobilityCut/femaleWalking.svg";
import bypassImg from "../img/mobilityCut/bypassCorridor.svg";
import bikeRackImg from "../img/mobilityCut/bikeRack.svg";
import permanentObstacle from "../img/mobilityCut/permanentObstacle.svg";
import gatheringZoneImg from "../img/mobilityCut/gatheringZone.svg";
import bicycleFrontImg from "../img/mobilityCut/bicycleFront.svg";
import bicycleBackImg from "../img/mobilityCut/bicycleBack.svg";
import smallOpenContainerImg from "../img/mobilityCut/openContainer25.svg";
import PTStopLeftImg from "../img/mobilityCut/PTStopLeft.svg";
import PTStopRightImg from "../img/mobilityCut/PTStopRight.svg";
import reservedParkingLaneFrontImg from "../img/mobilityCut/reservedParkingLaneFront.svg";
import reservedParkingLaneBackImg from "../img/mobilityCut/reservedParkingLaneBack.svg";
import { useTranslation } from 'react-i18next';

const PredefinedElements = () => {
  const { t } = useTranslation();

  // Array of predefined street elements with default widths and multiple icons
  const predefinedElements = [
    {
      streetElement: 'sidewalk',
      label: t('mobility-cut-app-sidewalk'),
      minWidth: '0.5',
      width: '1.0', 
      icons: [

         <div className="object-center flex justify-center"><img src={femaleWalkingImg} alt={t('mobility-cut-app-sidewalk')} /> </div>,
      ],
    },
    {
      streetElement: 'bypassCorridor',
      label: t('mobility-cut-app-bypassCorridor'),
      minWidth: '0.5',
      width: '1.0', 
      icons: [
        <div className="object-center flex justify-center"><img src={bypassImg} alt={t('mobility-cut-app-bypassCorridor')} /> </div>,
      ],
    },
    {
      streetElement: 'cycleLane',
      label: t('mobility-cut-app-cycleLane'),
      minWidth: '0.5',
      width: '0.8', 
      icons: [
       <div className="object-center flex justify-center"><img src={bicycleFrontImg} alt={t('mobility-cut-app-cycleLane')} /> </div>,
       <div className="object-center flex justify-center"><img src={bicycleBackImg} alt={t('mobility-cut-app-cycleLane')} /> </div>,
      ],
    },
    {
      streetElement: 'parkingLane',
      label: t('mobility-cut-app-parkingLane'),
      minWidth: '1.6',
      width: '2.0', 
      icons: [
        <div className="object-center flex justify-center"><img src={carFrontParkedImg} alt={t('mobility-cut-app-parkingLane')} /> </div>,
        <div className="object-center flex justify-center"><img src={carBackParkedImg} alt={t('mobility-cut-app-parkingLane')}/></div>,
        <div className="object-center flex justify-center"></div>,
      ],
    },
    {
      streetElement: 'reservedParkingLane',
      label: t('mobility-cut-app-reservedParkingLane'),
      minWidth: '1.6',
      width: '2.0', 
      icons: [
        <div className="object-center flex justify-center"><img src={reservedParkingLaneFrontImg} alt={t('mobility-cut-app-reservedParkingLane')} /> </div>,
        <div className="object-center flex justify-center"><img src={reservedParkingLaneBackImg} alt={t('mobility-cut-app-reservedParkingLane')} /> </div>,
      ],
    },
    {
      streetElement: 'bikeRack',
      label: t('mobility-cut-app-bikeRack'),
      minWidth: '1.0',
      width: '1.5', 
      icons: [
        <div className="object-center flex justify-start"><img src={bikeRackImg} alt={t('mobility-cut-app-bikeRack')} /> </div>,
        <div className="object-center flex justify-end"><img src={bikeRackImg} alt={t('mobility-cut-app-bikeRack')} /> </div>,
      ],
    },
    {
      streetElement: 'permanentObstacle',
      label: t('mobility-cut-app-permanentObstacle'),
      minWidth: '0.6',
      width: '0.8', 
      icons: [
        <div className="object-center flex justify-start"><img src={permanentObstacle} alt={t('mobility-cut-app-permanentObstacle')} /> </div>,
        <div className="object-center flex justify-end"><img src={permanentObstacle} alt={t('mobility-cut-app-permanentObstacle')} /> </div>,
      ],
    },
    {
      streetElement: 'busLane',
      label: t('mobility-cut-app-busLane'),
      minWidth: '2.6',
      width: '2.6', 
      icons: [
        <div className="object-center flex justify-center"><img src={busFrontImg} alt={t('mobility-cut-app-busLane')} /> </div>,
        <div className="object-center flex justify-center"><img src={busBackImg} alt={t('mobility-cut-app-busLane')} /> </div>,
      ],
    },
    {
      streetElement: 'busAndCycleLane',
      label: t('mobility-cut-app-busAndCycleLane'),
      minWidth: '2.6',
      width: '2.6', 
      icons: [
        <div className="object-center flex justify-center"><img src={busAndCycleFrontImg} alt={t('mobility-cut-app-busAndCycleLane')} /> </div>,
        <div className="object-center flex justify-center"><img src={busAndCycleBackImg} alt={t('mobility-cut-app-busAndCycleLane')} /> </div>,
      ],
    },
    {
      streetElement: 'busAndCarLane',
      label: t('mobility-cut-app-busAndCarLane'),
      minWidth: '2.6',
      width: '2.6', 
      icons: [
        <div className="object-center flex justify-center"><img src={busAndCarFrontImg} alt={t('mobility-cut-app-busAndCarLane')} /> </div>,
        <div className="object-center flex justify-center"><img src={busAndCarBackImg} alt={t('mobility-cut-app-busAndCarLane')} /> </div>,
      ],
    },
    {
      streetElement: 'tramLane',
      label: t('mobility-cut-app-tramLane'),
      minWidth: '2.5',
      width: '2.5', 
      icons: [
        <div className="object-center flex justify-center"><img src={tramImg} alt={t('mobility-cut-app-tramLane')} /> </div>,
      ],
    },
    {
      streetElement: 'middleBerm',
      label: t('mobility-cut-app-middleBerm'),
      minWidth: '0.2',
      width: '0.5', 
      icons: []
    },
    {
      streetElement: 'publicTransportStop',
      label: t('mobility-cut-app-publicTransportStop'),
      minWidth: '1.8',
      width: '2.0', 
      icons: [
        <div className="object-center flex justify-start"><img src={PTStopLeftImg} alt={t('mobility-cut-app-publicTransportStop')} /> </div>,
        <div className="object-center flex justify-end"><img src={PTStopRightImg} alt={t('mobility-cut-app-publicTransportStop')} /> </div>,
      ],
    },
    {
      streetElement: 'carLane',
      label: t('mobility-cut-app-carLane'),
      minWidth: '1.6',
      width: '2.5',
      icons: [
        <div className="object-center flex justify-center"><img src={carFrontImg} alt={t('mobility-cut-app-carLane')} /> </div>,
        <div className="object-center flex justify-center"><img src={carBackImg} alt={t('mobility-cut-app-carLane')} /> </div>,
        <div className="object-center flex justify-center"><img src={carBackDoubleImg} alt={t('mobility-cut-app-carLane')} /> </div>,
        <div className="object-center flex justify-center"> </div>,
      ],
    },
    {
      streetElement: 'scaffolding',
      label: t('mobility-cut-app-scaffolding'),
      minWidth: '0.6',
      width: '1.0',
      icons: []
    },
    {
      streetElement: 'scaffoldingPedestrianTunnel',
      label: t('mobility-cut-app-scaffoldingPedestrianTunnel'),
      minWidth: '0.6',
      width: '1.0',
      icons: []
    },
    {
      streetElement: 'containerOnParking',
      label: t('mobility-cut-app-containerOnParking'),
      minWidth: '1.8',
      width: '2.5',
      icons: [
        <div className="object-center flex justify-start"><img src={smallOpenContainerImg} alt={t('mobility-cut-app-containerOnParking')} /> </div>,
        <div className="object-center flex justify-end"><img src={smallOpenContainerImg} alt={t('mobility-cut-app-containerOnParking')} /> </div>,
      ],
    },
    {
      streetElement: 'sidewalkWithContainer',
      label: t('mobility-cut-app-sidewalkWithContainer'),
      minWidth: '1.8',
      width: '1.8',
      icons: [
        <div className="object-center flex justify-start"><img src={smallOpenContainerImg} alt={t('mobility-cut-app-sidewalkWithContainer')} /> </div>,
        <div className="object-center flex justify-end"><img src={smallOpenContainerImg} alt={t('mobility-cut-app-sidewalkWithContainer')} /> </div>,
      ],
    },
    {
      streetElement: 'workzone',
      label: t('mobility-cut-app-workzone'),
      minWidth: '0.5',
      width: '3.0',
      icons: []
    },
    {
      streetElement: 'gatheringZone',
      label: t('mobility-cut-app-gatheringZone'),
      minWidth: '2.0',
      width: '3.0',
      icons: [
        <div className="object-center flex justify-start"><img src={gatheringZoneImg} alt={t('mobility-cut-app-gatheringZone')} /> </div>,
        <div className="object-center flex justify-end"><img src={gatheringZoneImg} alt={t('mobility-cut-app-gatheringZone')} /> </div>,
      ],
    },
  ];

  return predefinedElements;
};

export default PredefinedElements;
